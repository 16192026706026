import React, { useEffect, Profiler, useState } from 'react'
// import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import "./Component-css/Downloadbutton.css?v=0.1"
// let deferredPrompt; 
const Downloadbutton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  const [isDivVisible, setDivVisibility] = useState(true);

  const toggleDiv = () => {
    setDivVisibility(!isDivVisible);
  };

  return (
    <div
      className="my-stickey-footer"
      style={{
        position: "fixed",
        bottom: "56px",
        width: "100%",
        maxWidth: "480px",
        backgroundColor: "rgb(17, 36, 54)"
      }}
    >
      {isDivVisible && (
        <div className="text-center download-button" style={{ zoom: "1.2", padding: "10px 0px" }}>
          <a href="" className="btn btn-success btn-sm" style={{ color: "#ffffff", fontWeight: 500 }} download>
            <img
              src="https://jaipurludo.com/Images/android.png"
              alt=""
              style={{ marginRight: 10 }}
              width="15px"

            />
            DOWNLOAD OUR APP <i class="fa-solid fa-download" style={{ color: "rgb(252, 252, 253)", marginLeft: "10px" }}></i>
            <img
              src="https://jaipurludo.com/Images/dowloadIcon.png"
              alt=""
              style={{ marginLeft: 10 }}
              width="13px"
            />
          </a>
          <i class="fa-solid fa-xmark" onClick={toggleDiv} style={{ position: "absolute", right: "15px", top: "10px", color: "white", cursor: "pointer", fontSize: '18px' }}></i>
        </div>
      )}
    </div>


  )
  
}

export default Downloadbutton