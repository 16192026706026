import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBHqgZZnA_NkpMUmQD4Nb3xnzLg_t9zDog",
  authDomain: "ludomahakal-73e14.firebaseapp.com",
  projectId: "ludomahakal-73e14",
  storageBucket: "ludomahakal-73e14.appspot.com",
  messagingSenderId: "524831472198",
  appId: "1:524831472198:web:a9203886906f2e8beea847",
  measurementId: "G-LCR3SNMGXD"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);