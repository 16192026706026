import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import WalletIcon from '@mui/icons-material/Wallet';
import PeopleIcon from '@mui/icons-material/People';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PersonIcon from '@mui/icons-material/Person';

const BottomNavBar = () => {

  const [value, setValue] = useState('/');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Update the selected value based on the current location
    setValue(location.pathname);
  }, [location.pathname]);
  
  const darkTheme = createTheme({
    palette: {
      main: '#ffffff',
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };

  return (
    <ThemeProvider theme={darkTheme}>
    <div
    className="my-stickey-footer"
    style={{
      position: "fixed",
      bottom: 0,
      width: "100%",
      maxWidth: 480,
      backgroundColor: "rgb(16, 34, 53)",
      zIndex: 10000, // Set a value based on your needs
    }}
  >
    {/* <div className="text-center download-button" style={{ zoom: "1.2", padding: "10px 0px" }}> */}
    <Box sx={{ 
              fontSize: darkTheme.typography.caption.fontSize,
              transition: 'none',
              fontWeight: 'bold',
              lineHeight: '20px',
          }}>
      <BottomNavigation
        showLabels
        value={value} onChange={handleChange} style={{ backgroundColor: 'rgb(16, 34, 53)' }}
      >
        <BottomNavigationAction
              label="Home"
              value="/"
              icon={<HomeIcon />}
              sx={{
                color: (theme) => (value === '/' ? '#ffffff' : '#ffffff'),'&.Mui-selected': {
                  color: '#00C0DC', // Set your desired selected color here
                },
              }}
            />
            <BottomNavigationAction
              label="Wallet"
              value="/wallet"
              icon={<WalletIcon />}
              sx={{
                color: (theme) => (value === '/wallet' ? '#ffffff' : '#ffffff'),
                '&.Mui-selected': {
                  color: '#00C0DC', // Set your desired selected color here
                },
              }}
            />
            <BottomNavigationAction
              label="Refer"
              value="/refer"
              icon={<PeopleIcon />}
              sx={{
                color: (theme) => (value === '/refer' ? '#ffffff' : '#ffffff'),
                '&.Mui-selected': {
                  color: '#00C0DC', // Set your desired selected color here
                },
              }}
            />
            <BottomNavigationAction
              label="Support"
              value="/support"
              icon={<SupportAgentIcon />}
              sx={{
                color: (theme) => (value === '/support' ? '#ffffff' : '#ffffff'),
                '&.Mui-selected': {
                  color: '#00C0DC', // Set your desired selected color here
                },
              }}
            />
            <BottomNavigationAction
              label="Profile"
              value="/Profile"
              icon={<PersonIcon />}
              sx={{
                color: (theme) => (value === '/Profile' ? '#ffffff' : '#ffffff'),
                '&.Mui-selected': {
                  color: '#00C0DC', // Set your desired selected color here
                },
              }}
            />
          </BottomNavigation>
    </Box>
     
    {/* </div> */}
  </div>
  </ThemeProvider>
  );
};

export default BottomNavBar;
